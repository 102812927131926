import moment from "moment-timezone"
import { memo } from "react";

const MailCard = ({ mail }) => {
    
    return (
        <>
            <div className="min-w-[100%] md:min-w-[50%] lg:min-w-[300px] p-5 py-6 bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition duration-300">
                <div className="flex flex-col justify-between items-center h-full mt-2 gap-y-3">
                    <div className="flex w-full items-end gap-x-3">
                        <strong className="text-[15px] text-gray-600 text-start">{mail.fromName}</strong>
                        <button className={`text-white text-[14px] font-semibold ${mail.mailbox === "INBOX" ? "bg-green-400" : "bg-red-400"} py-1.5 px-5 rounded-sm shadow-md hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 uppercase ml-auto`}>
                            {mail.mailbox === "INBOX" ? mail.mailbox : mail.mailbox.split("/")[1]}
                        </button>
                    </div>
                    <div className="w-full">
                        <p className="mt-1 text-start text-[12px] flex w-full justify-between gap-x-1">
                            <span className="font-semibold text-gray-700 text-start mr-2 w-max text-nowrap">Sub : </span>
                            <span className="text-end"> {mail.subject}</span>
                        </p>
                        <h1 className="text-[12px] w-full text-gray-700 word-wrap word-break flex justify-between gap-x-2 max-w-full mt-2">
                            <span className="font-semibold mr-2 font-semibold text-start">From :</span>
                            <span className="text-end text-[13px] max-w-[70%] " style={{ wordBreak: "break-word" }}>{mail?.from ? mail?.from : "No Email"}</span>
                        </h1>
                        <p className="mt-2 font-semibold text-gray-600 text-end text-[11px] w-full">{moment(mail?.date).tz("Asia/Kolkata").format("HH:MM DD-MM-YYYY")}</p>
                    </div>

                </div>
            </div>
        </>
    )
};

export default memo(MailCard);