import { memo } from "react"


const Search = ({ searchMail = () => "", searchType = "" }) => {
    return (
        <>
            <div className="w-max h-max z-20 flex justify-center items-center px-2 md:px-3 xl:px-4 lg:order-2 lg:ml-auto">
                <fieldset className="min-w-[220px] md:min-w-[180px] w-[100%] max-w-[290px] md:max-w-[260px] lg:max-w-[300px] lg:max-w-[350px] h-[40px] flex">
                    <input id={searchType} className="block border-b w-full md:min-px-[150px] lg:min-w-[160px] xl:min-w-[180px] outline-0 px-3 py-1 text-[12px] text-gray-500 text-center" type="text" placeholder={`Search name or email...`} />
                    <button onClick={() => searchMail({ queryString: document.getElementById(searchType).value })} className="text-[13px] font-semibold hover:border border-green-500 text-green-500 px-4">SEARCH</button>
                    <button onClick={() => {
                        document.getElementById(searchType).value = ""
                        searchMail({ queryString: "" })
                    }} className="text-[13px] font-semibold hover:border border-red-500 text-red-500 px-3 ml-1">FETCH</button>
                </fieldset>
            </div>
        </>
    )
}

export default memo(Search);