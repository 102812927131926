import { memo, useEffect, useMemo, useState } from "react";
import Search from "./Search";
import Clipboard from 'clipboard';

const Header = ({ mails, searchMail = () => null, notify }) => {

  const [toggleMails, setToggleMails] = useState(false)

  const [mailsToView, setMailsToView] = useState(null);


  const toggle_mailsCopy = () => {
    setToggleMails(prev => !prev);
  };

  const lis = useMemo(() => {
    return mails.mails.map((v, i) => (
      <li
        key={i}
        className={`p-0.5 lg:p-1 border border-teal-200 relative flex w-full md:w-[49%] lg:w-max lg:w-max justify-between items-center`}
      >
        <span
          className={`order-2 bg-teal-300 h-full px-1 md:px-1.5 lg:px-2 flex items-center w-max ${mailsToView === v.user ? "block" : "hidden"}`}
          style={{ boxShadow: "0 0 0 1px white" }}
        >
          <svg viewBox="0 0 24 24" fill="white" className="size-4">
            <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
          </svg>
        </span>

        <span
          className={`absolute -top-[7px] -left-1 flex text-[11px] text-teal-300 px-0.5 pr-1 lg:pr-1.5 bg-white`}
          style={{ boxShadow: "0 0 0 1px white", }}
        >
          {v.mails.length}
        </span>

        <button
          id={"mailsBtn" + v.user}
          onClick={() => setMailsToView(v.user)}
          className="px-1 md:px-1.5 lg:px-2 py-1 cursor-pointer w-full text-[10px] md:text-[11px] lg:text-[11px] xl:text-[13px]"
        >
          {v.user}
        </button>
      </li>
    ));
  }, [mails, mailsToView]);

  const clickToCopy = async (e) => {
    e.currentTarget.classList.add("bg-white");
    e.currentTarget.classList.add("text-teal-400");

    const mailIds = Array.from(document.getElementById("mailIdsToCopy").children)
      .map(li => li.children[2].textContent)
      .join(";");

    try {
      e.currentTarget.setAttribute('data-clipboard-text', mailIds);
      const clipboard = new Clipboard(e.currentTarget, {
        text: () => mailIds,
      });
      notify("✅ Email IDs Copied!");
    } catch (err) {
      notify("❌ Failed to Copy!");
      console.error("Failed to copy:", err);
    }
  };

  useEffect(() => {
    if (mailsToView !== null) {
      let element = document.getElementById(mailsToView);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center"
        })
      }
    }
  }, [mailsToView])


  return (

    <div className="w-full flex flex-col lg:flex-row sticky top-0 left-0 right-0 min-h-[70px] h-max py-2 z-20 bg-white justify-center items-center lg:items-end lg:px-3.5 md:px-3 px-2.5 xl:px-4 shadow-md">
      <Search searchType="email" searchMail={searchMail} />
      <div className={`w-full md:w-full lg:w-[72%] xl:w-[73%] ${toggleMails ? "flex" : "hidden lg:flex"} flex-col lg:flex-row justify-center items-start lg:items-start h-full order-1 px-2`}>
        <button id="copyMails" onClick={clickToCopy} className='lg:absolute right-4 top-2 h-max copyMails w-full md:w-[60px] border border-teal-400 text-[13px] rounded-sm cursor-pointer bg-teal-400 text-gray-100 flex justify-center items-center py-1'>Copy</button>
        <ul id="mailIdsToCopy" className='px-0.5 md:px-1 xl:px-2 mt-2 lg:mt-0 order-1 ml-auto lg:ml-0 lg:mr-auto flex flex-wrap gap-x-2 lg:gap-x-2 gap-y-2 text-[11px] lg:text-[13px] text-teal-300 w-full justify-start'>
          {lis}
        </ul>
      </div>

      <button onClick={toggle_mailsCopy} className='absolute z-30  bg-white p-1 right-4 w-[38px] h-[38px] -bottom-[38px] stroke-gray-800 shadow-md flex lg:hidden'>
        { !toggleMails ? 
        <svg viewBox="0 0 24 24" strokeWidth={1.5} stroke="" className="w-full h-full">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
        </svg>
          :
          <svg viewBox="0 0 24 24" strokeWidth={1.5} stroke="" className="w-full h-full">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
        }
      </button>

    </div>

  )
}

export default memo(Header)