import { useMemo } from "react";
import MailCard from "./mailCard";
import data from "../data.json"


const CardList = ({ mails }) => {

    function yr (yr){
       return new Date().getFullYear() - yr;
    }
 
    
    const users = useMemo(() => {
        return mails.mails.length > 0 && mails.mails.map((user, userIndex) => {
            return <div id={user.user} key={userIndex} className="w-full usersMailTab flex flex-col px-6 py-10 mt-2 bg-white">
                
                <div className="flex flex-wrap">
                    <div className="w-full lg:w-[100%] flex flex-col">
                <h4 className="w-full gap-x-2 gap-y-1 flex flex-wrap items-center text-start text-[16px] md:text-[19px] lg:text-[22px] font-bold text-gray-600 uppercase" >
                   <span> {user.user} </span>
                {data.filter(y=> y.email === user.user).length > 0 ? <span className="text-[12px] h-[24px] px-4 bg-green-100 text-green-600" style={{lineHeight:"24px"}}> {yr(data.find(y=> y.email === user.user).year || new Date().getFullYear())} {yr(data.find(y=> y.email === user.user).year) > 1 ? "Years" : "Year"} {data.find(y=> y.email === user.user).state}</span> : "" }
                </h4>
                
                <strong className="font-semibold text-gray-500 text-start text-[13px] lg:text-[16px] mt-1">Last {user.mails.length} mails ( Spam : {user.mails.filter(spm => spm.mailbox !== "INBOX").length} ) and ( Inbox : {user.mails.filter(spm => spm.mailbox === "INBOX").length} )</strong>
                </div>
                
                </div>

                <div className="w-full flex relative">
                    <ul id={"cont"+userIndex} className="flex flex-row max-w-[100%] justify-start overflow-x-auto gap-x-3 px-[16px] py-8 ">
                        {user?.mails?.sort((a, b) => {
                            let dateA = new Date(a.date);
                            let dateB = new Date(b.date);
                            return dateB - dateA
                        }).map((mail, mailIndex) => {
                            return <MailCard key={mailIndex} mail={mail} />
                        })}
                    </ul>
                </div>

            </div>
        })
    }, [mails.mails])
    return (
        <div id="mailsContainer" className="w-full h-max bg-gray-100">
            {users}
        </div>
    )
};

export default CardList;