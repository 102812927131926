import './App.css';
import { useEffect, useState } from "react";
import axios from "axios";
import Header from './component/header';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Layout from './component/layout';
// import AddMails from './page/addMails';
import toast, {Toaster} from "react-hot-toast"
const baseUrl =process.env.REACT_APP_BASEURL || "http://3.133.85.123:8001"
// const baseUrl = "https://mails-access.vercel.app/api";
const config = {
  headers: {
    "Content-Type": "application/json"
  }
}

function App() {

  const notify = (e) => toast(e);

  // Function to fetch mails from the server
  const [mails, setMails] = useState({
    mails: [],
    loading: false,
    success: false,
    error: false,
    message: ""
  });

  let [accounts, setAccounts] = useState([]);

  useEffect(() => {
    fetch({ queryString: "", accounts: accounts })
  }, [])

  const searchMail = ({ queryString }) => {
    fetch({ queryString, accounts })
  }

  async function fetch({ queryString = "", accounts }) {
    try {
      setMails(m => ({ ...m, loading: true }))
      const res = await axios.post(`${baseUrl}/mails`, { queryString, accounts }, config);

      if (res.data.success) {
        setMails(m => ({ ...m, loading: false, success: true, mails: res.data.mails }))
      }

      setTimeout(() => {
        setMails(m => ({ ...m, success: false, message: "" }))
      }, 3000)
  
    } catch (error) {
      console.log("error fetching mails ", error)
    }
  }

  return (
    <div className="App">
          <Toaster />

      <BrowserRouter>
        { mails.mails.length > 0 && <Header notify={notify} searchMail={searchMail} mails={mails}/>}
        <Routes>
          <Route path='/' element={<Layout mails={mails} accounts={accounts} setAccounts={setAccounts} setMails={setMails} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;