import { BarLoader } from "react-spinners";
import CardList from "./cardList";
import { memo, useEffect } from "react";
import Hero from "./hero";




const Layout = ({ mails, setMails, accounts, setAccounts }) => {
  // let storedAccounts = localStorage.getItem("mail-credentails");
    
//   useEffect(()=>{
//     // if(storedAccounts){
      
//     //   let parsed = JSON.parse(storedAccounts);
//     //   setAccounts(parsed)
//       fetch({ queryString: "", accounts : accounts })
//     // }
//   // },[storedAccounts])
// },[])
    

    return (
        <>

            <Hero/>
            {(mails.loading && !mails.success && !mails.error) ?
                <div className='fixed top-0 left-0 right-0 bottom-0 h-full w-full z-10 flex justify-center items-center bg-white'>
                    <BarLoader color='red' />
                </div>
                :
                (mails.success || mails.mails.length > 0) &&
                <CardList mails={mails} />
            }
        </>
    )
}

export default memo(Layout);