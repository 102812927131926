

const Hero = () => {
    return (
        <>
            <div className="w-full h-max flex flex-wrap justify-center items-start lg:items-center py-4 pb-6 min-h-max lg:min-h-[60vh] xl:min-h-[73vh]">
                <div className="w-max">
                    <img className="h-[25vh] md:h-[35vh] xl:h-[45vh]" src="./images/undraw_new_message_re_fp03.svg" alt="" />
                </div>
                <div className="min-w-[100%] lg:w-[60%] md:min-w-[60%] mt-4 lg:ml-10">
                <h2 className="text-[22px] flex flex-col items-center lg:items-start lg:text-[36px] font-bold mt-4 w-full px-4 lg:px-0 rowdies-light">
                   <span className="w-max text-gray-800"> Boosting Your Email Results </span>
                   <span className="w-max text-teal-400 lg:pr-8 border border-0 border-b-2 border-teal-400 py-1"> Use Our Inbox Testing </span>
                </h2>
                    <p className="text-center text-justify lg:text-start dongle-regular mt-6 lg:mt-8 w-full px-6 lg:px-0 lg:px-0 text-gray-600 text-[19px] md:text-[21px] lg:text-[26px]">Our Email inbox testing ensures your emails reach the intended inbox rather than the spam folder. By sending test emails to various addresses with different spam filters, you can evaluate deliverability, identify potential issues, and optimize your campaign for better performance. It’s a crucial step for successful email marketing.</p>
                </div>
            </div>
        </>
    )
};

export default Hero